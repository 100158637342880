// Variables
@import "../../assets/css/vars";

footer {
    .whatsapp-float {
        position: fixed;
        bottom: 40px;
        right: 40px;
        width: 70px;
        height: 70px;
        border-radius: 50%;
        background-color: #25D366;
        align-items: center;
        justify-content: center;
        display: flex;
        color: #fff;
        font-size: 30px;
        z-index: 999;
        box-shadow: 0 3px 8px #00000063;
    }

    img {
        max-width: 100%;
        max-height: 192px;
    }

    .info {
        padding: 50px 25px;
        background-color: $dark-blue;

        .contact {
            text-align: left;

            a {
                color: $white;
                font: $big-size-sbold;
                display: block;
                margin-bottom: 5px;
            }
        }

        .social-media {
            margin-top: 30px;

            a {
                color: $white;
                background-color: $dark-blue-one;
                width: 60px;
                height: 60px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                font-size: 30px;
                margin: 0 7px;
                transition: all 0.2s;

                &:hover {
                    background-color: $light-blue;
                    color: $blue;
                }
            }
        }
    }

    .copywrights {
        padding: 30px 0;
        background-color: $dark-blue-one;
        text-align: center;

        span {
            color: $white;
            font: $med-size-reg;

            a {
                text-decoration: underline;
                color: $white;
                font: $med-size-bold;
            }
        }
    }

    @media (max-width: 768px) {
        .info {
            .contact {
                text-align: center;
                margin-bottom: 20px;

                a {
                    font: $med-size-sbold;
                }
            }

            .social-media {
                text-align: center;
            }
        }

        .copywrights {
            span {
                font: $small-size-sbold
            }
        }
    }
}