// Variables
@import "../../assets/css/vars";

header {
    background-color: $white;

    nav {
        @media (min-width: 768px) {
            height: 100px;
        }

        a {
            &.navbar-brand {
                img {
                    height: 70px;
                }
            }
        }

        ul {
            li {
                display: inline-flex;
                align-items: center;

                a {
                    font: $small-size-bold;
                    color: $blue;
                    transition: all 0.2s;

                    &.button {
                        padding: 9px 24px !important;
                        border-radius: 25px;
                        transition: all 0.2s;
                    }

                    &.sign-up {
                        background-color: $blue;
                        color: $white;
                        margin-right: 13px;

                        &:hover {
                            background-color: $dark-blue;
                        }
                    }

                    &.sign-in {
                        background-color: $light-blue;
                        color: $blue;
                        margin-right: 9px;

                        &:hover {
                            background-color: $dark-blue;
                            color: $white;
                        }
                    }

                    &.selected {
                        color: $dark-blue;
                    }

                    &:hover:not(.button) {
                        color: $dark-blue;
                    }
                }

                @media (max-width: 768px) {
                    display: block;
                }
            }

            .user-menu {
                a {
                    &.dropdown-toggle {
                        &::before {
                            display: inline-block;
                            margin-left: 5px;
                            vertical-align: 0.255em;
                            content: "";
                            border-top: 0.3em solid;
                            border-right: 0.3em solid transparent;
                            border-bottom: 0;
                            border-left: 0.3em solid transparent;
                            margin-bottom: -3px;
                        }

                        &::after {
                            content: unset !important;
                        }
                    }
                }

                .dropdown-menu {
                    text-align: right;
                    min-width: 13rem;
                    right: 0;

                    a {
                        display: inline-flex;
                        align-items: center;
                        justify-content: space-between;
                        font: $small-size-bold;

                        &:hover {
                            background-color: $light-blue;
                        }
                    }
                }

                img {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    margin-right: 5px;
                    margin-top: -3px;
                }
            }
        }
    }

    @media (max-width: 768px) {
        .navbar-toggler {
            background-color: $blue;
            outline-color: $dark-blue;

            span {
                color: #fff;
            }
        }

        nav {
            ul {
                padding: 0;

                li {
                    a {
                        &.sign-in,
                        &.sign-up {
                            display: block;
                            margin-right: 0;
                            width: 100%;
                            text-align: center;
                            margin-top: 5px;
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }
    }
}